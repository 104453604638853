import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CTA = styled.div`
  display: flex;
  line-height: 1;
  gap: 8px;
  white-space:nowrap;
  align-items: center;
`

export const Low = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 48px;
  justify-content: ${({ type }) =>
    type == 'false' ? 'space-between' : 'flex-start'};
  font-weight: 400 ;
  align-items: center;
  line-height: 2;
  padding: 3px 30px;
  ${ BREAK_.md_dn }{
    padding: 3px 24px;
  };
`

export const Separator = styled.div`
  width: 100%;
  // width: calc(100% - 10px);
  // margin-left: 10px;
  min-height: 1px;
  background: ${({ color }) => `${color}22`};
  ${ BREAK_.md_dn }{
    width: calc(100% - 0px);
    margin:0 auto;
  };
  height:1px;
`

export const Status = styled.div`
  
  color: ${({ color }) => color};
  // font-size: 1rem;
  position: absolute;
  top: 16px;
  right: ${({ mode }) => mode === "card" ? "16px" : "24px" };
  ${ BREAK_.md_dn }{
    font-size:0.95rem;
    // top:18px;
    right:15px;
  };
`
export const MoveDate = styled.div`
  font-weight: 600;
  color: ${({ color }) => color};
  font-size: 1rem;
  position: absolute;
  top: ${({ mode }) => mode === "card" ? "35px" : "30px" };
  right: ${({ mode }) => mode === "card" ? "16px" : "24px" };
  
  ${ BREAK_.md_dn }{
    font-size:0.95rem;
    top: ${({ mode }) => mode === "card" ? "35px" : "38px" };
    right: ${({ mode }) => mode === "card" ? "16px" : "16px" };
  };
`