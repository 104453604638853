import { Box, Button } from "@mui/material";
import {
  Main,
  Option,
  Cell,
  Text,
  SubText,
  Values,
} from "./ShiftProperty.style";
import { useNavigate } from "react-router-dom";
import { Portal } from "components/Portal/Portal";
import { Backdrop } from "styles/styles.backdrop";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useBreak } from "hooks/useBreak";
import { useTranslation } from "react-i18next";
import { getFocus, useAtom, useGet, useSet } from "state/jotai";
import {
  active_project,
  currentActiveProject,
  current_language,
  iso_free,
} from "state/store.global";
import { _bootstrap } from "state/store.bootstrap";
import { useEffect, useState } from "react";
import { Icon } from "assets/Icons_";
import { _project } from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

export const ShiftProperty = ({ showProperty, setShowProperty, type, isIsomtery }) => {
  const setActiveID = useSet(active_project);
  const dropdownOption = getFocus(_bootstrap, "projectnames");
  const noOfVacantUnits = getFocus(_bootstrap, "projects");
  const { pin, pid } = useGet(active_project);
  const currentLanguage = useAtom(current_language)[0];
  const isDesktop = useBreak("md_up");
  const [activeProject, setActiveProject] = useAtom(currentActiveProject);
  const navigate = useNavigate();
  const [face, setFace] = useState("");
  const setRmode = useAtom(iso_free)[1];
  const allUnits = getFocus(_project, 'units');
  const options = getFocus(_project, "filteroptions");

  useEffect(() => {
    if (Object.keys(dropdownOption).length && pid) {
      setFace(
        dropdownOption[currentLanguage]?.filter((d) => d.PID === pid)[0]
          ?.URLSafe || ""
      );
    }
  }, [pid, currentLanguage]);

  const {
    i18n: { language },
  } = useTranslation();

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  return (
    <>
      <AnimatePresence>
        {showProperty && (
          <>
            <Main as={motion.div} {...animation} clr={clr} isDesktop={isDesktop}>
              <Box
                sx={{
                  color: "#fff",
                  borderBottom: "1px solid #fff",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%", padding: "10px", display: "flex", justifyContent: "center" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <p style={{ fontSize: "0.875rem" }}>Vælg den ejendom som du ønsker at se</p>
                  </Box>
                </Box>
                <Box
                  sx={{ padding: "16px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setShowProperty(false);
                    }, 150);
                  }}
                >
                  <Icon icon={"close"} size="xl" isFaIcon={true} />
                </Box>
              </Box>
              {dropdownOption[currentLanguage].map(
                ({ path, Presentation, URLSafe, PID, PIN }, key) => {
                  if (PIN === pin && PID === pid) return null;
                  const active = URLSafe == face ? true : false;

                  const saletype = allUnits[PIN]?.[PID]?.Units[0]?.Data?.SalesTypeLejelejlighed?.Value || "";
                  const totalUnits = allUnits[PIN][PID]?.NumberOfUnits || "";
                  const moveInDates = allUnits[PIN]?.[PID]?.Units[0]?.Data?.Indflytningsprisp?.Value || "";
                  const roomSize = options[PIN]?.[PID]?.filter((p) => p?.Label == "Størrelse")?.[0] || "";
                  const noOfRooms = options[PIN]?.[PID]?.filter((p) => p?.Label == "Værelser")?.[0] || "";
                  const hardcodedMoveInDate = allUnits?.[PIN]?.[PID]?.Units[0]?.Data?.Indflytningsdatoproperty?.UnformattedValue || "";
                    // const availableRoom = (allUnits[PIN]?.[PID]?.Units.filter((u) => u?.Data?.UnitStatusID?.Value == "10")).length;
                    

                  const click = (url) => {
                    if (PIN <= 3) {
                      setActiveID({
                        pid: PID,
                        pin: PIN,
                      });
                      navigate("/" + language + path);
                      setActiveProject(Presentation);
                      setTimeout(() => {
                        setShowProperty(false);
                      }, 150);
                    }
                  };
                  if (PIN <= 3  ) {
                    return (
                      <Option key={key} active={active}>
                        <Cell>
                          <Text clr={clr}>{URLSafe}</Text>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: isDesktop ? "row" : "column",
                                width: "100%",
                              }}
                            >
                              <SubText>
                                <Values>Type: {saletype ? "Udlejning" : "Salg"}</Values>
                                <Values>Antal enheder: {totalUnits !== "" ? totalUnits : ""}</Values>
                                <Values>Flyt ind: {hardcodedMoveInDate !== "" ? hardcodedMoveInDate : ""}</Values>
                              </SubText>
                              <SubText>
                                <Values>Størrelser: {roomSize ? `${roomSize.MinValue} - ${roomSize.MaxValue} ${roomSize.Postfix}` : ""}</Values>
                                <Values>Værelser: {noOfRooms ? `${noOfRooms.MinValue} - ${noOfRooms.MaxValue}` : ""}</Values>
                                  {/* <Values>Ledige: {availableRoom}</Values> */}
                              </SubText>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                sx={{
                                  height: "fit-content",
                                  padding: "7px 30px",
                                  borderRadius: "8px",
                                  background: "#CB883F",
                                  "&:hover": { background: "#B67A38" },
                                }}
                                variant="contained"
                                size="large"
                                onClick={() => {
                                  click(URLSafe);
                                  setRmode(false);
                                }}
                              >
                                Vælg
                              </Button>
                            </Box>
                          </Box>
                        </Cell>
                      </Option>
                    );
                  }
                }
              )}
              <PoweredBy clr={clr?.icons} />
            </Main>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
