import { useState } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { getFocus, useAtom, useGet } from "state/jotai";
import { Box, Button, Stack } from "@mui/material";
import { API, MOVEINCALCULATIONS, STATICS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import {
  active_project,
  vis_calculate_benefit,
  vis_movein_price,
} from "state/store.global";
import { housing_benefit } from "state/store.global";
import { gross_income } from "state/store.global";
import {
  Container,
  Inner,
  Result,
  SubText,
  CurrencyInput,
  SideText,
} from "./HousingBenefit.style";
import "./HousingBenefit.css";
import { useHousingBenefit } from "./HousingBenefit.hook";
import { _project } from "state/store.projects";
import { useFetch } from "hooks/fetch/useFetch";
import { useActiveUnit } from "components/Unit";
import { _bootstrap } from "state/store.bootstrap";


const data = {
  Header1: "Beregn din boligstøtte",
  Text1:
    "Indtast din husstands årlige bruttoindtægt (månedsløn før skat x 12), og få lavet en vejledende beregning for din månedlige boligstøtte",
  Header2: "Husstandens årlige bruttoindtægt",
  Header3: "Din vejledende boligstøtte",
  SubText:
    "Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den endelige boligstøtte du måtte kunne få. Brug eventuelt https://www.boligstoette.dk/ som har en beregner der stilles til rådighed af Udbetaling Danmark.",
};

const StyledImage = styled.img`
  width: 637px;
  height: 200px;
  object-fit: cover;
`;

const Row = styled.div`
  padding: 0.4rem 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;

export const MoveInPricePortal = () => {
  const [, setmovein] = useAtom(vis_movein_price);
 
  const type = useGet(_bootstrap).type;

  const poly = type === 'poly';
  const file = poly ? 'main/ProjectData.jsx' : '';
 
  const unit = useActiveUnit();
  const { pin, pid } = useGet(active_project);
  const images = unit.getImages();
  
  const D = pid ? MOVEINCALCULATIONS( pid, unit?.data?.UnitID) : null;
  const DPC = useFetch({ url: D, file }).D; /*getting the data for move in price-calculations */
// console.log(DPC,"dpcc")
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const icon = {
    icon: "close6",
    color: "#fff",
    wrap: { position: "absolute", top: 20, right: 20 },
    size: "20",
    click: () => setmovein(false),
  };

  const imageUrl = images[2]?.url;

  const isInvalidValue = (value) =>
    value === null || value === "0" || value === "0,0" || value === "999999" || value === "999.999";

  return (
    <>
      <Backdrop
        onClick={() => {
          setmovein(false);
        }}
      />
      <Container>
        <StyledImage src={imageUrl} alt="Unit Image" />
        <Icon {...icon} />
        <Inner>
          <h2>Hvad er indflytningspris?</h2>
          <div style={{ display: "flex", paddingBottom: "24px", fontSize: "17px" }}>
            <p>
              Indflytningspris er det beløb, du skal betale for at kunne flytte ind.
              <p>Det dækker over:</p>
            </p>
          </div> 
          <Box>
            <div>
              <Row>
                <div>Første måneds husleje*</div>
                <div>{isInvalidValue(DPC?.first_months_rent) ? "N/A" : `${DPC?.first_months_rent} kr.`}</div>
              </Row>
              <Box sx={{ height: 5 }} />
              <Line />
              <Box sx={{ height: 5 }} />
              <Row>
                <div style={{ display: "flex", gap: "4px" }}>
                  Forudbetalt husleje <SideText>(1 måned x husleje)</SideText>
                </div>
                <div>{isInvalidValue(DPC?.prepaid_rent) ? "N/A" : `${DPC?.prepaid_rent} kr.`}</div>
              </Row>
              <Box sx={{ height: 5 }} />
              <Line />
              <Box sx={{ height: 5 }} />
              <Row>
                <div style={{ display: "flex", gap: "4px" }}>
                  Depositum <SideText>(3 måneder x husleje)</SideText>
                </div>
                <div>{isInvalidValue(DPC?.deposit) ? "N/A" : `${DPC?.deposit} kr.`}</div>
              </Row>
              <Box sx={{ height: 5 }} />
              <Line />
              {!isInvalidValue(DPC?.account_consumption) && (
                <Row>
                  <div>Første måneds aconto forbrug</div>
                  <div>{`${DPC?.account_consumption} kr.`}</div>
                </Row>
              )}
            </div>
            {!isInvalidValue(DPC?.account_consumption) && <Line />}
            <div style={{ height: 10 }} />
            {!isInvalidValue(DPC?.moveinprice) && (
              <Row>
                <div style={{ fontWeight: "600" }}>Indflytningspris</div>
                <div>
                  <div style={{ fontWeight: "600" }}>{`${DPC?.moveinprice} kr.`}</div>
                </div>
              </Row>
            )}
            <Box sx={{ height: 5 }} />
            <Line />
          </Box>
          <SubText>*Første måneds husleje kan være mindre (afhængigt af din indflytningsdato)</SubText>
          <Stack direction="row" spacing="1px" justifyContent="center"></Stack>
        </Inner>
      </Container>
    </>
  );
};
