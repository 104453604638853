import { Box } from "@mui/material";
import { FlexBox, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";

export const OverviewDualMenu = ({left, right, nav, pin, openSelectFunction, showSelectFunction, setShowSelectFunction, clickList, list, buttonsLabel, showProperty, setShowProperty, toggleDrawer, config, rotatemode, click2, turnBuilding, isrotate, click1, clickModel,showOldSlider}) => {
    const type = getFocus(_bootstrap, "type");

    const isMobile = useBreak("md_dn");
    const here = useLocation().pathname;
    const isIsometry = here.includes("isometry")

    const frame = getIsoData("activeframe");
    const frames = getIsoData("frames");
    const index = getIsoData("snapindex");
    const setIndex = setIsoData("snapindex");
    const setFrame = setIsoData("activeframe");
    const percent = getIsoData('percent')

    const showButton = [
        "list",
        "compare",
        "favorites",
        "gallery",
        "downloads",
      ].some((i) => here.includes(i));
    
    const props = { type, isIsometry, pin }
    return <>
        <FullWidth
          isMobile={isMobile}
          left={left}
          right={right}
          nav={nav}
          clr={clr}
          isBgTrue={isMobile ? true: false}
        >
              <FlexBox clr={clr}> 
            {
                isMobile ? 
                <Section clr={clr} onClick={openSelectFunction} padding="20px">
                    <Icon
                        icon="ellipsisvertical"
                        color={clr.primary_text}
                        isFaIcon={true}
                        size="xl"
                    />
                    <SelectFunction
                        showSelectFunction={showSelectFunction}
                        setShowSelectFunction={setShowSelectFunction}
                    />
                </Section>
                :
                <>
                    <Box
                    sx={{
                        width: isMobile && !isIsometry ? '100%' : "auto",
                        display: "flex",
                        justifyContent: isMobile && !isIsometry ? "space-between" : "flex-start",
                        gap: "16px",
                        position: "relative",
                    }}
                    >
                         {!turnBuilding && (
                        <Section
                        {...props}
                            clr={clr}
                            active={here.includes("list")}
                            onClick={clickList}
                        >
                            <Icon {...list} isFaIcon={true} color={"#000"} />
                            <div className="paragraph1-regular" style={{ color: "#000" }}>
                            {
                                buttonsLabel?.filter(
                                (btn) => btn.UILabelProperty === "Boligliste"
                                )[0]?.Label
                            }
                            </div>
                        </Section>
                         )}
                            {!turnBuilding && (
                        <Section
                        {...props}
                        onClick={() => {
                            setShowProperty(!showProperty);
                            toggleDrawer();
                        }}
                        clr={clr}
                        showButton={showButton}
                        >
                        <Icon
                            icon="buildings"
                            size="lg"
                            color={"#000"}
                            isFaIcon={true}
                        />
                        <div className="paragraph1-regular"  style={{ color: "#000" }}>
                            Skift ejendom
                        </div>
                        <ShiftProperty
                        {...props}
                            showProperty={showProperty}
                            setShowProperty={setShowProperty}
                        />
                        </Section>
                            )}
                        {(config?.IMAGE_SNAPFRAMES?.length > 0) && isIsometry && !isMobile && !showOldSlider &&(
                            <Section
                            {...props}
                            clr={clr}
                            onClick={rotatemode && isrotate ? click1 : click2}
                            >
                            <>
                                {!turnBuilding ? (
                                <>
                                    <Icon
                                    icon="rightarrowrotate"
                                    color={"#000"}
                                    isFaIcon={true}
                                    size="lg"
                                    />{" "}
                                    <div className="paragraph1-regular"  style={{ color: "#000" }}>
                                    {
                                        buttonsLabel?.filter(
                                        (btn) => btn?.UILabelProperty === "Drejbygning"
                                        )[0]?.Label
                                    }
                                    </div>
                                </>
                                ) : (
                                <>
                                    <Icon
                                    icon={isrotate ? "pause" : "play"}
                                    color={"#000"}
                                    isFaIcon={true}
                                    size="lg"
                                    />{" "}
                                    <p style={{ color: "#000" }}>{isrotate ? "Stop rotation" : "Start rotation"}</p>
                                </>
                                )}
                            </>
                            </Section>
                        )}
                    </Box>
                    <Box>
                        <Section width="none">
                            {!rotatemode && percent == 100 && (
                                <>
                                {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                                    <Controls
                                    {...{
                                        frame,
                                        setFrame,
                                        config,
                                        frames,
                                        index,
                                        setIndex,
                                    }}
                                    />
                                )}
                                </>
                            )}
                        </Section>
                    </Box>
                </>
            }
             
            </FlexBox>
        </FullWidth>
    </>
}